import React, { useState, useEffect, useContext} from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom'
import jwt from 'jwt-decode';
import { LanguageContext, WhiteLabelContext, AccountContext } from "ToolboxUtils/web/context/context";
import ResetPasswordView from "ToolboxComponents/webapp/pages/reset-password-view/reset-password-view";
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import getPath from 'Utils/web/get-path';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const ResetPassword = props => {
  const history = useHistory();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [account, setAccount] = useContext(AccountContext).usePath();

  const [error, setError] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!tokenConfirmed.isComplete) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
      if (localStorage.token) {
        localStorage.clear();
        setAccount(undefined);
      }
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  useEffect(() => {
    if (account) {
      history.push('/');
    }
  }, [account]);

  const sendLogs = error => {
    apiChuchoteurs.post(`/logs`, {
      body: {error: {...error, type: 'front'}}
    })
  };

  const tokenConfirmed = useApiChuchoteurs.get(`/accounts/password/`, {
    params: {token: props.match.params.token, whiteLabelId: whiteLabel.id},
    onComplete: (results) => {
      if (results.isHttpError) {
        setError(true);
        if (results.error) {
          sendLogs(results.error);
        }
      }
    }
  });

  useIsContentLoaded(tokenConfirmed.isComplete);

  return (
    <>
      {tokenConfirmed ?
        <ResetPasswordView
          isError={error === true ? true : false}
          isPasswordUpdated={passwordUpdated === true ? true : false}
          onSubmit={async (password) => {
            try {
              const passwordEdited = await apiChuchoteurs.put(`/accounts/password`, {
                body: {token: props.match.params.token, password: password}
              })
              setPasswordUpdated(true);
              setTimeout(function(){
                history.push(getPath('home', whiteLabel, language));
              }, 5000);
            } catch (error) {
              if (error.status === 404) {
                setError(true);
              }
              apiChuchoteurs.post(`/logs`, {
                body: {error: {...error, type: 'front'}}
              })
            }
          }}
        />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
}

export default ResetPassword;
